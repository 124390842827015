.to-do {
  position: relative;
  color: #fcfcfc;
  background-color: #3c3c3c;
  padding: clamp(15px, 2vw, 35px) clamp(15px, 2vw, 30px);
  width: calc(100% - 40px);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  float: right;
}

.to-do__title {
  font-size: 18px;
  font-weight: bold;
  word-break: break-word;
}

.to-do__checkbox {
  position: absolute;
  left: -40px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.to-do:not(:first-of-type) {
  margin-top: 20px;
}

@media only screen and (min-width: 450px) {
  .to-do {
    float: unset;
    width: 100%;
  }
}

.completed-to-do {
  width: 100%;
}
