.to-do-form {
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: clamp(10px, 2vw, 25px);
  width: 100%;
}

.to-do-form input {
  border: 2px solid black;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: clamp(15px, 2vw, 25px) clamp(15px, 2vw, 30px);
  background-color: #fbfbfb;
  font-size: 1.1rem;
  -webkit-transition: ease-in-out 100ms;
  transition: ease-in-out 100ms;
}

.to-do-form button {
  padding: clamp(15px, 2vw, 25px) clamp(15px, 2vw, 30px);
  border-radius: 3px;
  border: unset;
  cursor: pointer;
  background-color: #ceef86;
  border: 2px solid #ceef86;
  font-size: 1.1rem;
  white-space: nowrap;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.to-do-form button:hover {
  background-color: #c5ec6f;
}
