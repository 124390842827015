.active-to-do-wrapper {
  width: 100%;
  margin-top: 30px;
}

.active-to-do-wrapper .actions-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.active-to-do-wrapper .actions-wrapper img {
  width: 30px;
  cursor: pointer;
  -webkit-transition: ease-in-out 100ms;
  transition: ease-in-out 100ms;
}

.active-to-do-wrapper .actions-wrapper img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@media only screen and (max-width: 450px) {
  .active-to-do-wrapper .actions-wrapper {
    position: relative;
    left: 40px;
  }
}

.active-to-do-wrapper .active-to-dos {
  width: 100%;
}

.floating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  -webkit-animation: float 2s ease-in-out infinite;
          animation: float 2s ease-in-out infinite;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.floating .up-arrow {
  width: 30px;
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.inline {
  display: inline;
}
